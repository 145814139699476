.container {
  margin: 10px 0 20px;

  .trackingStatus {
    font-size: 25px;
    line-height: 1.39;
    letter-spacing: -0.21px;
  }

  .customerLogoContainer {
    margin-left: auto;

    .customerLogo {
      max-width: 300px;
      max-height: 60px;
    }
  }
}
