.container {
  td,
  tbody {
    tr {
      font-size: 10px;

      &:hover {
        background-color: transparent;
        border-bottom: 1px solid #d1d1d1;
        cursor: inherit;
      }
    }
  }
}
