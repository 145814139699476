.submitButtonContainer {
  margin-left: 0 !important;
  margin-top: 2px;

  .submitButton {
    height: 28px;
    width: 70px;
    margin-left: 15px;
    letter-spacing: 0.06em;
  }
}
