@import "~normalize.css/normalize.css";
@import "~react-toggle/style.css";
@import "~@dpdgroupuk/mydpd-ui/styles/index";

#root {
  height: 100vh !important;
}

.mydpd-table-row:hover,
.cursor-pointer {
  cursor: pointer;
}

.material-form-group-error .form-text {
  overflow: visible !important;
}

form.search-form {
  .mydpd-card {
    height: 165px;
  }

  .mydpd-postcode-finder-container {
    input {
      width: 178px !important;
    }
  }
}

.resultsStep {
  .mydpd-table-container {
    border: none !important;
  }
}

.results-table {
  .mydpd-table {
    tbody {
      td {
        white-space: normal;
      }
    }
  }
}

.button {
  height: 28px;
  margin-left: 15px;
  letter-spacing: 0.06em;
}

form#createReturnForm {
  .form-group {
    margin-bottom: 22px;
  }

  .mydpd-postcode-finder-container {
    .form-group {
      margin-bottom: 18px;
    }
  }
}

.parcel-filters {
  .mydpd-card-step-text {
    width: 100%;
  }
}

span.navbar-brand {
  cursor: pointer;
}
