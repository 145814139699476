.formContainer {
  width: 100%;
}

.titleContainer {
  display: flex;
  justify-content: space-between;

  .addFilterButton {
    margin-right: -30px !important;
    cursor: pointer;
  }
}

.filterButtonsCol {
  :first-child {
    margin-right: 20px;
  }

  .filterButton {
    cursor: pointer;
  }
}

.filterRow {
  margin-bottom: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}
