@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.reviewCard {
  border-radius: 8px;
  padding: 15px;

  .reviewRow {
    height: 20px !important;
  }

  .title {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;

    > div {
      flex-grow: 1;
    }
  }

  .text {
    margin-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.0025em;
    color: $blue_1;
    cursor: pointer;
  }

  .body {
    padding: 0;
    font-size: 10px;
  }
}
