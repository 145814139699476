@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.header {
  font-weight: 500;
  font-size: 18px;
  color: $black;
}

.text {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
