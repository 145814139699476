.container {
  margin-top: 40px;
}

.electric {
  color: #1ca521;
  font-size: 15px;
  letter-spacing: 0.23px;
  margin-left: 30px;
}

.block {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}

.status {
  margin: 10px 0;
}
