.issue_status {
  font-size: 25px;
  line-height: 1.39;
  letter-spacing: -0.21px;
}

.reasonStatus {
  font-size: 14px;

  .errorMessage {
    display: block;
    font-weight: bold;
    color: red;
  }
}

.subtext {
  font-size: 12px;
  font-style: italic;
}

.issue_logo {
  max-width: 300px;
  max-height: 60px;
  margin-right: 15px;
}

.inner {
  display: flex;
  align-items: center;
}
