.options_row {
  display: flex;
  flex-direction: row;
}

.option_wrapper {
  margin: 2px;
  width: calc(25% - 4px);

  &:first-child,
  &:nth-child(4) {
    width: calc(25% - 2px);
  }

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(4) {
    margin-right: 0;
  }
}
